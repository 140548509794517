






























































import Vue from 'vue';
import {Component, PropSync} from 'vue-property-decorator';
import {mdiClose, mdiTranslate} from "@mdi/js";

@Component export default class SettingsDialog extends Vue {

    @PropSync('remoteUrl', { type: String }) propRemoteURL!: string;
    @PropSync('metaconfiguration', { type: String }) propMetaconfiguration!: string;

    // If the whole window is opened
    dialog: boolean = false;

    icons = {
        translate: mdiTranslate,
        close: mdiClose,
    };

    /**
     * Open the setting window
     */
    public show() {
        this.dialog = true;
    }

    /**
     * Returns list of available languages for select picker
     */
    private get languageList(): {text: string, textEn: string, value: string}[] {
        let result: {text: string, textEn: string, value: string}[] = [];
        for (let code in this.$root.$i18n.messages) {
            result.push({
                text: this.$root.$i18n.messages[code]['_lang_local'] as string,
                textEn: this.$root.$i18n.messages[code]['_lang_english'] as string,
                value: code,
            });
        }
        return result;
    }
}

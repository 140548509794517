

































    import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import ColaLayoutSettingsComponent from "../layout/layouts/ColaLayout/ColaLayoutSettingsComponent.vue";
import {ColaLayoutOptions} from "../layout/layouts/ColaLayout/ColaLayout";
    import {LayoutManager} from "../layout/LayoutManager";
@Component({
    components: {ColaLayoutSettingsComponent}
})
export default class LayoutDialog extends Vue {
    @Prop() layouts !: LayoutManager;

    private dialog: boolean = false;

    // Which tab is currently opened
    private tab: number = 0;

    // Which layout is active (as tab number)
    private startTab: number = 0;

    show() {
        this.tab = Math.max(0, this.layouts.list.indexOf(this.layouts.currentLayoutData));
        this.startTab = this.tab;
        this.dialog = true;
    }

    @Watch('dialog')
    private dialogChanged(value: boolean) {
        if (!value) this.close(false);
    }

    /**
     * Layout is changed only if confirmed is true
     * @param confirmed
     */
    close(confirmed: boolean) {
        if (confirmed) {
            this.layouts.switchToLayout(this.layouts.list[this.tab].name);
        } else {
            this.tab = this.startTab;
        }
        this.dialog = false;
    }
}

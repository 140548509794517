import { render, staticRenderFns } from "./ColaLayoutSettingsComponent.vue?vue&type=template&id=7b16821a&scoped=true&"
import script from "./ColaLayoutSettingsComponent.vue?vue&type=script&lang=ts&"
export * from "./ColaLayoutSettingsComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ColaLayoutSettingsComponent.vue?vue&type=style&index=0&id=7b16821a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b16821a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VCheckbox,VCol,VList,VListItem,VRow,VSubheader,VTextField})

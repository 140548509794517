

































































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import {Node, NodeType} from "../../../graph/Node";
import {mdiEye, mdiEyeOff, mdiFilter, mdiFilterOutline, mdiGroup, mdiMagnify} from "@mdi/js";
import LinkComponent from "../../helper/LinkComponent.vue";
import GraphManipulator from "../../../graph/GraphManipulator";
import NodeCommon from "@/graph/NodeCommon";
import NodeGroup from "@/graph/NodeGroup";

interface NodeTypeGroup {
    type: NodeType;
    nodes: NodeCommon[];
}

@Component({
    components: { LinkComponent }
})
export default class NodeGroupedList extends Vue {
    @Prop() groups: NodeTypeGroup[];
    @Prop(Boolean) modeHiddenNodes: boolean;
    @Prop() manipulator !: GraphManipulator;

    @Prop(Boolean) deleteButton!: boolean;
    @Prop(Boolean) hideButton!: boolean;
    @Prop(Boolean) showButton!: boolean;
    @Prop(Boolean) selectButton!: boolean;
    @Prop(Boolean) unselectButton!: boolean;
    @Prop(Boolean) groupButton!: boolean;
    @Prop(Boolean) breakGroupButton!: boolean;
    @Prop(Boolean) splitGroupButton!: boolean;


    private readonly icons = {
        visibility: [mdiEyeOff, mdiEye],
        filter: [mdiFilterOutline, mdiFilter],
        zoomIcon: mdiMagnify,
        group: mdiGroup,
    }

    private fetchTypesLoading = false;
    private async fetchTypes(nodes: NodeCommon[]) {
        this.fetchTypesLoading = true;
        let promises = []
        for (let node of nodes) {
            if (node instanceof Node) promises.push(node.useDefaultView());
        }

        await Promise.all(promises);
        this.fetchTypesLoading = false;
    }

    private searchValue: String = "";
    private filterNodes(nodes: NodeCommon[]) {
        // nodes = nodes.filter(node => node instanceof Node);

        nodes = nodes.sort((n1,n2) => {
            let label1: string = "";
            let label2: string = "";

            if (n1 instanceof Node) label1 = n1.currentView?.preview?.label.toLowerCase();
            else if (n1 instanceof NodeGroup) label1 = n1.getName.toLowerCase();

            if (n2 instanceof Node) label2 = n2.currentView?.preview?.label.toLowerCase();
            else if (n2 instanceof NodeGroup) label2 = n2.getName.toLowerCase();

            if ( label1 > label2 ) {
                return 1;
            }

            if ( label1 < label2 ) {
                return -1;
            }

            return 0;
        });

        let filteredNodes = nodes;
        if (this.searchValue != "" && this.searchValue) {
            filteredNodes = nodes.filter(node => { 
                if (node instanceof Node) return node.currentView?.preview?.label.toLowerCase().includes(this.searchValue.toLowerCase());
                else if (node instanceof NodeGroup) return node.getName.toLowerCase().includes(this.searchValue.toLowerCase());
            })
        }

        return filteredNodes;
    }
    
    private getNodes(node: NodeCommon) {
        if (node instanceof NodeGroup) {
            return node.nodes;
        } else if (node.children?.length > 0) {
            return node.children;
        }
    }
    
    private isNode(node: NodeCommon) {
        return (node instanceof Node)
    }

    private getLabel(node: NodeCommon) {
        if (node instanceof Node) return node.currentView?.preview ? node.currentView.preview.label : "-";
        if (node instanceof NodeGroup) return node.getName;

    }

    @Watch('groups')
    private groupsUpdated() {
        this.fetchTypesLoading = false;
    }

    @Emit('nodeSelected')
    private nodeSelected(node: NodeCommon) {
        return node;
    }

    private groupDelete(group: NodeTypeGroup) {
        for (let node of group.nodes) {
            node.remove();
        }
    }

    private groupVisibility(group: NodeTypeGroup, visibility: boolean) {
        for (let node of group.nodes) {
            node.visible = visibility;
        }
    }

    private groupSelection(group: NodeTypeGroup, selection: boolean) {
        for (let node of group.nodes) {
            node.selected = selection;
        }
    }

    private groupMakeGroup(group: NodeTypeGroup) {
        this.manipulator.groupExistingNodes(group.nodes);
    }

    private groupBreakGroup(group: NodeTypeGroup) {
        this.manipulator.leaveGroup(group.nodes, group.nodes[0].belongsToGroup);
    }

    private groupSplitGroup(group: NodeTypeGroup) {
        this.manipulator.splitGroup(group.nodes, group.nodes[0].belongsToGroup);
    }
}

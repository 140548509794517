










import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import DegreeFilterData from "./DegreeFilterData";
import RangeCard from "../../../component/filter/RangeCard.vue";

@Component({
    components: {
        RangeCard
    }
})
export default class DegreeFilterSettingsTabItem extends Vue {
    @Prop() filter !: DegreeFilterData;

    private graphFiltersNamesOrder = [
        "sumDegree",
        "inDegree",
        "outDegree",
    ];
}



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Graph } from '../../graph/Graph';
import { FiltersList } from "../../filter/Filter";

@Component
export default class FilterDialog extends Vue {
    /**
     * List of filters and their data
     */
    @Prop() filter: FiltersList;

    /**
     * Reference to graph instance because we need to ask some data...
     */
    @Prop() graph: Graph;

    public show() {
        this.dialog = true;
    }

    public close() {
        this.dialog = false;
    }

    /**
     * Flattens the array of filters.
     */
    private get tabs() {
        let tabs = [];

        for (let specificFilter of this.filter.filters) {
            for (let tab of specificFilter.tabs) {
                tabs.push({...tab, filter: specificFilter.filter})
            }
        }

        return tabs;
    }

    dialog: boolean = false;
}

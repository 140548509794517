






































    import {Component, Emit, Ref, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component export default class LoadDialog extends Vue {
    @Ref() readonly fileInput !: HTMLInputElement;
    @Emit('selected') emitSelected(file: File) {return file}
    @Prop() loadUrlFunction !: (url: string) => Promise<boolean>;

    private selectedFile: any[];
    private dialog: boolean = false;

    show(): void {
        // Reset the value
        if (this.fileInput) this.fileInput.value = null;
        this.dialog = true;
        this.error = false;
        this.loading = false;
    }

    private fileSelected(files: FileList) {
        if (files)
            this.emitSelected(files[0]);
        this.dialog = false;
    }

    private url: string = "";
    private error: boolean = false;
    private loading: boolean = false;
    private async urlSelected() {
        this.error = false;
        this.loading = true;
        let result = await this.loadUrlFunction(this.url);
        this.loading = false;
        if (result) {
            this.dialog = false;
            this.url = "";
        } else {
            this.error = true;
        }
    }
}

















































import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import DagreLayout from "./DagreLayout";
import LayoutSettingsComponentGroup from "../LayoutSettingsComponentGroup.vue";

@Component({
    components: {LayoutSettingsComponentGroup}
})
export default class DagreLayoutSettingsComponent extends Vue {
    /**
     * ColaLayout whose options will be modified by user.
     */
    @Prop() layout !: DagreLayout;
}


























































































































































































    import {Component, Emit, Mixins, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import Metaconfiguration from "../configurations/Metaconfiguration";
import { mdiChevronRight, mdiHome } from '@mdi/js';
import Configuration from "../configurations/Configuration";
import SearchComponent from "./SearchComponent.vue";
import ConfigurationManager from "../configurations/ConfigurationManager";
    import {Graph} from "../graph/Graph";
    import {RemoteServer} from "../remote-server/RemoteServer";
    import GraphSearcher from "../searcher/GraphSearcher";
    import Searcher from "../searcher/Searcher";
    import LocalGraphSearcher from "../searcher/searchers/LocalGraphSearcher";
    import SimpleJsonSearcher from "../searcher/searchers/SimpleJsonSearcher";
    import IRIConstructorSearcher from "../searcher/searchers/IRIConstructorSearcher";
    import IRIIdentitySearcher from "../searcher/searchers/IRIIdentitySearcher";
    import GraphManipulator from "../graph/GraphManipulator";
    import NodeCommonPanelMixin from "./side-panel/NodeCommonPanelMixin";

    export enum ConfigurationChooserComponentModes {
        NEW_GRAPH,
        CHANGE_CONFIGURATION
    }

@Component({
    components: {SearchComponent}
})
export default class ConfigurationChooserComponent extends Mixins(NodeCommonPanelMixin) {
    @Prop() defaultMetaconfiguration !: Metaconfiguration;
    @Prop() configurationManager !: ConfigurationManager;
    @Prop() remoteServer !: RemoteServer;
    @Prop() graph !: Graph;
    @Prop() graphManipulator !: GraphManipulator;
    @Prop() graphSearcher !: GraphSearcher;
    @Prop() mode !: ConfigurationChooserComponentModes;

    // If this dialog is opened
    private dialog: boolean = false;

    // Selected metaconfiguration
    private metaconfiguration: Metaconfiguration = null;

    // Selected configuration, if not null, panels are switched
    private configuration: Configuration = null;

    // If the custom panel is opened
    private customPanel: boolean = false;

    // Breadcrumbs of metaconfigurations (navigation in history)
    private breadcrumbs: Metaconfiguration[] = [];

    // Which tab is opened in custom panel section
    private customPanelTab: number = 0;

    /**
     * Helper method to get list of languages for language selector.
     * Todo: create component of it
     * */
    private get languages() {
        let result = [];
        for (let language in this.$i18n.messages) {
            result.push({
                text: this.$i18n.messages[language]['_lang_local'],
                value: language,
            });
        }
        return result;
    }

    /**
     * Returns a list of starting nodes for third panel (node selection).
     * */
    private get startingNodesList() {
        let list = [];
        if (this.configuration) {
            for (let iri of this.configuration.startingNode) {
                let label = this.graph.nodes[iri]?.currentView?.preview?.label;
                let classes = this.graph.nodes[iri]?.currentView?.preview?.classes ?? [];
                list.push({
                    loading: !label,
                    label: label ?? iri,
                    classes,
                    iri,
                });
            }
        }
        return list;
    }

    /**
     * Emits event of changing the configuration.
     * @param configuration new configuration which should replace the old one
     * @param newGraph whether the old graph should be discarded.
     * */
    @Emit()
    private ConfigurationUpdate(configuration: Configuration, newGraph: boolean) {
        return {
            configuration,
            newGraph,
        }
    }

    /**
     * Emits request to load data from file
     * */
    @Emit()
    private LoadFromFile() {
        this.close();
    }

    private get currentPanelOpen(): number {
        if (this.customPanel) return 1;
        if (this.configuration) return 2;
        return 0;
    }

    /**
     * When the language is changed, send request to load those languages.
     * */
    @Watch('$i18n.locale')
    private languageChanged() {
        this.metaconfiguration?.sync(this.$i18nGetAllLanguages());
        this.configuration?.sync(this.$i18nGetAllLanguages());
    }

    /**
     * 0 - no error
     * 1 - metaconfiguration failed
     * 2 - configuration failed
     * 3 - custom configuration failed
     * 4 - custom configuration OK
     */
    private customPanelErrorSuccessType: number = 0;
    private customShowAlert: boolean = true;
    private get customPanelErrorSuccessTypeMessage(): string {
        switch (this.customPanelErrorSuccessType) {
            case 1: return 'metaconfiguration_failed';
            case 2: return 'configuration_failed';
            case 3: return 'custom_configuration_failed';
            case 4: return 'custom_configuration_succeeded';
        }
    }

    private icons = {
        breadcrumbSeparator: mdiChevronRight,
        home: mdiHome
    };

    private get breadcrumbsData(): {
        index: number,
        metaConfiguration: Metaconfiguration,
        home: boolean,
    }[] {
        let result = [];
        if (this.breadcrumbs.length && this.breadcrumbs[0] != this.defaultMetaconfiguration) {
            result.push({
                index: -1,
                metaConfiguration: this.defaultMetaconfiguration,
                home: true,
            });
        }

        result = result.concat(this.breadcrumbs.map((metaConfiguration, index) => { return {
            index,
            metaConfiguration,
            home: metaConfiguration == this.defaultMetaconfiguration,
        } }));
        return result;
    }

    /**
     * Sets new meta configuration and inserts it into the breadcrumbs (history).
     * */
    private selectMetaconfiguration(metaconfiguration: Metaconfiguration) {
        this.breadcrumbs.push(this.metaconfiguration);
        this.metaconfiguration = metaconfiguration;
        this.metaconfiguration.sync(this.$i18nGetAllLanguages());
    }

    /**
     * Method is called by UI elements when user selects specific configuration.
     * */
    private selectConfiguration(configuration: Configuration) {
        if (this.mode == ConfigurationChooserComponentModes.NEW_GRAPH) {
            this.configuration = configuration;

            if (this.configuration) {
                this.ConfigurationUpdate(this.configuration, true);
                this.nodeSelectionLoading = false;
                this.nodeSelectionError = false;
            }
        }

        if (this.mode == ConfigurationChooserComponentModes.CHANGE_CONFIGURATION) {
            this.ConfigurationUpdate(configuration, false);
            this.close();
        }
    }

    /**
     * Returns in breadcrumbs.
     * */
    private returnInHistory(toIndex: number) {
        if (toIndex == -1) {
            this.metaconfiguration = this.defaultMetaconfiguration;
            this.breadcrumbs = [];
        } else {
            this.metaconfiguration = this.breadcrumbs[toIndex];
            this.breadcrumbs = this.breadcrumbs.slice(0, toIndex);
        }
    }

    //#region Custom panel
    @Watch('customPanel')
    private customPanelChanged() {
        this.customShowAlert = false;
    }
    //#endregion Custom panel

    //#region Custom panel / meta configurations

    // Whether loading is in process
    private customMetaConfigurationLoading: boolean = false;

    // IRI of the loaded metaconfiguration
    private customMetaConfiguration: string = "";

    // On button click
    private async customLoadMetaConfiguration() {
        this.customPanelErrorSuccessType = 0;
        this.customMetaConfigurationLoading = true;
        let metaConfiguration = this.configurationManager.getOrCreateMetaconfiguration(this.customMetaConfiguration);
        let ok = await metaConfiguration.sync(this.$i18nGetAllLanguages());

        this.customShowAlert = !ok;
        this.customPanelErrorSuccessType = 1;

        this.customMetaConfigurationLoading = false;

        if (ok) {
            this.customPanel = false;
            this.selectMetaconfiguration(metaConfiguration);
            this.customMetaConfiguration = "";
        }
    }

    //#endregion Custom panel / meta configurations

    //#region Custom panel / configuration

    // Whether loading is in process
    private customConfigurationLoading: boolean = false;

    // IRI of the loaded metaconfiguration
    private customConfigurationIRI: string = "";

    // On button click
    private async customLoadConfiguration() {
        this.customPanelErrorSuccessType = 0;
        this.customConfigurationLoading = true;
        let configuration = this.configurationManager.getOrCreateConfiguration(this.customConfigurationIRI);
        let ok = await configuration.sync(this.$i18nGetAllLanguages());

        this.customShowAlert = !ok;
        this.customPanelErrorSuccessType = 2;

        this.customConfigurationLoading = false;

        if (ok) {
            this.customPanel = false;
            this.customConfigurationIRI = "";
            this.selectConfiguration(configuration);
        }
    }

    //#endregion Custom panel / configuration

    //#region Custom panel / custom configuration

    private customCustomConfigurationLoading: boolean = false;

    private customCustomConfigurationIRI: string = "";

    private customCustomConfigurationStylesheet: string = "";
    private customCustomConfigurationIriPattern: string = "";
    private customCustomConfigurationAutocomplete: string = "";

    private async customCustomConfigurationLoad() {
        this.customPanelErrorSuccessType = 0;
        this.customCustomConfigurationLoading = true;
        let conf = this.configurationManager.getOrCreateConfiguration(this.customCustomConfigurationIRI);
        let ok = await conf.sync(this.$i18nGetAllLanguages());
        this.customCustomConfigurationLoading = false;
        this.customShowAlert = true;
        this.customPanelErrorSuccessType = ok ? 4 : 3;

        if (ok) {
            this.customCustomConfigurationStylesheet = conf.stylesheet.length ? conf.stylesheet[0] : null;
            this.customCustomConfigurationIriPattern = conf.resourcePattern;
            this.customCustomConfigurationAutocomplete = conf.autocomplete.join('\n');
        }
    }

    private customCustomConfigurationConfirm() {
        let configuration = new Configuration(this.customCustomConfigurationIRI, null);
        configuration.autocomplete = this.customCustomConfigurationAutocomplete.split('\n');
        configuration.stylesheet = [this.customCustomConfigurationStylesheet];
        configuration.resourcePattern = this.customCustomConfigurationIriPattern;

        this.selectConfiguration(configuration);
        this.customPanel = false;
    }

    //#endregion Custom panel / custom configuration

    //#region Node selection panel
    @Watch('graph')
    @Watch('configuration.startingNode')
    private fetchNodes() {
        if (this.configuration && this.graph) {
            for (let iri of this.configuration.startingNode) {
                this.graph.getOrCreateNode(iri);
            }
        }
    }

    private nodeSelectionLoading: boolean = false;
    private nodeSelectionError: boolean = false;
    /**
     * Final method when user selects a node in current configuration. It should try to fetch it and if succeeds it
     * should emit configuration changed event.
     * @param iri
     */
    private async nodeSelectionSelectNode(iri: string) {
        this.nodeSelectionLoading = true;
        this.nodeSelectionError = false;

        if (await this.graphManipulator.locateOrTryFetchNode(iri)) {
            // On success
            this.configuration = null;
            this.dialog = false;
        } else {
            this.nodeSelectionError = true;
        }

        this.nodeSelectionLoading = true;
    }

    //#endregion Node selection panel

    //#region Dialog show close methods
    /**
     * Shows the dialog.
     * It is expected that all changes were saved and therefore the graph can be replaced
     * */
    public show(metaConfigurationIri: string = undefined) {
        if (!this.metaconfiguration) {
            this.metaconfiguration = this.defaultMetaconfiguration;
        }
        if (metaConfigurationIri) {
            this.selectMetaconfiguration(this.configurationManager.getOrCreateMetaconfiguration(metaConfigurationIri));
        }
        this.metaconfiguration.sync(this.$i18nGetAllLanguages());
        this.configuration = null;
        this.customPanel = false;
        this.dialog = true;
    }

    public async showConfiguration(configurationIri: string) {
        let configuration = this.configurationManager.getOrCreateConfiguration(configurationIri);
        await configuration.sync(this.$i18nGetAllLanguages());
        this.show();
        this.configuration = configuration;
    }

    public close() {
        this.dialog = false;
    }

    //#endregion Dialog show close methods

}









import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import ColaLayout from "./ColaLayout";
import {mdiLayersTriple} from "@mdi/js";

@Component
export default class ColaLayoutButtons extends Vue {
    @Prop() layout !: ColaLayout;
    private icon = mdiLayersTriple;
}

















import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class SaveDialog extends Vue {
    private dialog: boolean = false;
    private modal: boolean = false;
    private resolver: (value: 'yes'|'no'|'back') => void = () => null;

    private action(value: 'yes'|'no'|'back') {
        this.resolver(value);
        this.dialog = false;
    }

    @Watch('dialog')
    private onDialogChanged(value: boolean) {
        if (!value) {
            this.action("back");
        }
    }

    show(modal: boolean): Promise<'yes'|'no'|'back'> {
        this.dialog = true;
        this.modal = modal;

        return new Promise((resolve) => {
            this.resolver = resolve;
        });
    }
}

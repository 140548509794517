


































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import ViewOptions from "../graph/ViewOptions";
@Component export default class ViewOptionsDialog extends Vue {
    @Prop() options !: ViewOptions;

    dialog: boolean = false;
    toggle_one: any = null;

    show() {
        this.dialog = true;
    }

}

















import Component from "vue-class-component";
import Vue from "vue";
import 'vuetify/src/components/VBtnToggle/VBtnToggle.sass';

@Component export default class PanelTemplate extends Vue {}

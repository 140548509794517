









































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import { Node, NodeType } from '../../graph/Node';
import GroupRenameDialog from '@/component/side-panel/components/GroupRenameDialog.vue'

import {
    mdiTrashCanOutline,
    mdiEye,
    mdiEyeOff,
    mdiPinOffOutline,
    mdiPinOutline,
    mdiHammer,
    mdiCrosshairsGps,
    mdiApplicationExport,
    mdiRenameBox
} from '@mdi/js';

import NodeGroupedList from "./components/NodeGroupedList.vue";
import GraphManipulator from "../../graph/GraphManipulator";
import GraphAreaManipulator from "../../graph/GraphAreaManipulator";
import NodeGroup from "../../graph/NodeGroup";
import NodeCommonPanelMixin from "./NodeCommonPanelMixin";
import PanelTemplate from "./components/PanelTemplate.vue";
import PanelActionButton from "./components/PanelActionButton.vue";
import NodeCommon from '@/graph/NodeCommon';

interface NodeTypeGroup {
    type: NodeType;
    nodes: NodeCommon[];
}

@Component({
    components: {GroupRenameDialog, PanelActionButton, PanelTemplate, NodeGroupedList}
})
export default class DetailGroupPanel extends Mixins(NodeCommonPanelMixin) {
    @Prop() nodeGroup: NodeGroup;
    @Prop(Object) areaManipulator !: GraphAreaManipulator;
    @Prop(Object) manipulator !: GraphManipulator;
    @Prop(Boolean) nodeLockingSupported !: boolean;

    private readonly icons = {
        remove: mdiTrashCanOutline,
        visibility: [mdiEyeOff, mdiEye],
        lockedForLayouts: [mdiPinOffOutline, mdiPinOutline],
        break: mdiHammer,
        locate: mdiCrosshairsGps,
        leave: mdiApplicationExport,
        rename: mdiRenameBox,
    }

    removeNodes() {
      const groupNodes = this.nodeGroup.nodes;

      this.nodeGroup.remove()
      
      for (const node of groupNodes) {
        this.$root.$emit('deletion', node);
      }
    }

    visibilityChanged() {
        this.nodeGroup.visible = !this.nodeGroup.visible;
        if ((this.areaManipulator?.visualGroups.length > 0) && this.nodeGroup.parent?.identifier.startsWith("pseudo_parent")) {
            if (this.nodeGroup.parent?.children?.every(childNode => childNode.visible === false)) this.nodeGroup.parent.visible = false;
            else this.nodeGroup.parent.visible = true;
        }
    }

    get previewClasses(): {label: string; color: string}[] {
        return this.getClassesColors(this.nodeGroup.classes);
    }

    get groupedNodes(): NodeTypeGroup[] {
        let map = new Map<string, NodeTypeGroup>();
        for (let node of this.nodeGroup.nodes) {
            let type: NodeType;
            if (node instanceof Node) type = node.currentView?.preview?.type;
            if (node instanceof NodeGroup) type = node.mostFrequentType;
            let group: NodeTypeGroup;
            if (map.has(type?.iri)) {
                group = map.get(type?.iri);
            } else {
                group = {
                    type,
                    nodes: []
                };
                map.set(type?.iri, group);
            }

            group.nodes.push(node);
        }

        return Array.from(map.values());
    }

    public get isGroupCompactModeActive() {
        return this.areaManipulator.graphArea.modeGroupCompact;
    }
}





















import NodeGroup from '@/graph/NodeGroup';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
@Component({})
export default class AddNode extends Vue {
    @Prop() group: NodeGroup;
    dialog: boolean = false;
    newName: string = "";

    show(predefinedIRI: string = null, firstPanel: boolean = false) {
        this.dialog = true;
       
    }

    private confirmed() {
        this.group.name = this.newName;
        this.dialog = false;
    }

}


















































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import FilterDataEnum from "../../filter/FilterDataEnum";
import clone from "clone";

@Component
export default class PropertyEnumTab<T> extends Vue {
    @Prop() availableItems: T[];
    @Prop() equalityComparator: (a: T, b: T) => boolean;

    /**
     * When set to true, the label under the modeListed filed describe this button as "what to do with new nodes" while
     * normally the description will be how it really works.
     * */
    @Prop(Boolean) propertiesAreDisjoint !: boolean;

    private modeListed: boolean = false;

    /**
     * Current filter
     */
    @Prop() value: FilterDataEnum<T>;

    /**
     * Changes value of all items.
     *
     * true - check all
     * false - uncheck all
     * */
    public changeAll(value: boolean) {
        if (value) {
            this.selectedItems = [...Array(this.availableItems.length).keys()];
        } else {
            this.selectedItems = [];
        }
    }

    @Watch('value.modeListed', {immediate: true})
    private modeListedUpdate(val: boolean) {
        this.modeListed = val;
    }

    /**
     * The reason why modeListed was split into two variables is because we want to change both modeListed and
     * array of items in the same AnimationFrame. Naive approach would change modeListed in the first frame, than
     * in the second frame the graph re-renders and array changes which triggers re-rendering again in the next frame
     * */
    @Watch('modeListed')
    private localModeListedChanged(val: boolean) {
        // When the action came from parent
        if (val == this.value.modeListed) return;

        // We need to negate the array
        let newArray: number[] = [];
        for (let itemId in this.availableItems) {
            if (!this.selectedItems.includes(Number(itemId))) {
                newArray.push(Number(itemId));
            }
        }

        // Change both values simultaneously
        this.selectedItems = newArray; // (it has getter which changes this.value)
        this.value.modeListed = val;
    }

    /**
     * Returns list of items that should be selected. It depends on modeListed.
     */
    get selectedItems(): number[] {
        let items: number[] = [];
        for (let itemId in this.availableItems) {
            let found = false;
            for (let selectedItem of this.value.items) {
                if (this.equalityComparator(this.availableItems[itemId], selectedItem)) {
                    found = true;
                    if (this.value.modeListed) {
                        items.push(Number(itemId));
                        break;
                    }
                }
            }
            if (!found && !this.value.modeListed) {
                items.push(Number(itemId));
            }
        }

        return items;
    }

    /**
     * Properly sets the data structure
     * @param itemsIds
     */
    set selectedItems(itemsIds: number[]) {

        let items = [];
        for (let itemId in this.availableItems) {
            if (itemsIds.includes(Number(itemId)) ? this.value.modeListed : !this.value.modeListed) {
                items.push(this.availableItems[itemId]);
            }
        }

        // Prevent Vuex going into a loop
        if (!this.checkEqualArrays(this.value.items, items)) {
            this.value.items = items;
        }
    }

    checkEqualArrays(a: T[], b: T[]): boolean {
        if (a.length != b.length) return false;

        for (let ai of a) {
            let found = false;
            for (let bi of b) {
                if (this.equalityComparator(ai, bi)) {
                    found = true;
                    break;
                }
            }
            if (!found) return false;
        }

        return true;
    }
}

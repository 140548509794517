












import Component from "vue-class-component";
import Vue from "vue";
import {Emit, Prop} from "vue-property-decorator";
import VueI18n from "vue-i18n";

@Component export default class PanelActionButton extends Vue {
    @Prop(String) icon !: string;
    @Prop(String) text !: string;
    @Prop(String) help !: string;

    @Prop({type: Number, default: 1/5}) width !: number;
    @Prop(Boolean) danger !: boolean;
    @Prop(Boolean) disabled !: boolean;

    @Emit() click() {}
}

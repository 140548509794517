









import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {mdiWeb} from "@mdi/js";

@Component
export default class LinkComponent extends Vue {
    @Prop(String) private href: string;

    private readonly icons = {
        link: mdiWeb,
    }
}

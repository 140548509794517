
















import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {LayoutsCommonGroupSettings} from "./LayoutsCommon";

@Component
export default class CircleLayoutSettingsComponent extends Vue {
    @Prop() layout !: LayoutsCommonGroupSettings;
}

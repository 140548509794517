

































































import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import ColaLayout from "./ColaLayout";
import LayoutSettingsComponentGroup from "../LayoutSettingsComponentGroup.vue";
@Component({
    components: {LayoutSettingsComponentGroup}
})
export default class ColaLayoutSettingsComponent extends Vue {
    /**
     * ColaLayout whose options will be modified by user.
     */
    @Prop() layout !: ColaLayout;
}























































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import { Graph } from '../graph/Graph';
import SearchComponent from "./SearchComponent.vue";
import GraphManipulator from "../graph/GraphManipulator";
import GraphSearcher from "../searcher/GraphSearcher";
@Component({
    components: {SearchComponent}
})
export default class AddNode extends Vue {
    @Prop({type: Object as () => Graph}) graph: Graph;
    @Prop() manipulator: GraphManipulator;
    @Prop() private graphSearcher: GraphSearcher;

    multipleEntry: boolean = false;
    IRI: string = "";
    IRIs: string = "";
    dialog: boolean = false;
    loading: boolean = false;
    error: boolean = false;

    tab: number = 0;


    get IRIsLines(): string[] {
        if (this.IRIs === null) return [];
        return this.IRIs.split(/\r\n|\r|\n/).map(s => s.trim()).filter(s => s.length > 0);
    }

    show(predefinedIRI: string = null, firstPanel: boolean = false) {
        if (firstPanel) {
            this.tab = 0;
        }
        this.dialog = true;
        this.error = false;
        this.loading = false;
        if (predefinedIRI) {
            this.IRI = predefinedIRI;
        }
    }

    multipleLoading: boolean = false;
    multipleActual: number = 0;
    multipleTotal: number = 1;

    private onSearcher(IRI: string) {
        this.dialog = false;
        this.manipulator.locateOrTryFetchNode(IRI);
    }

    async confirmed() {
        if (this.tab === 0) {

            this.loading = true;
            this.error = false;

            if (await this.manipulator.locateOrTryFetchNode(this.IRI)) {
                this.$root.$emit('addNode', [this.IRI]);

                this.IRI = "";
                this.dialog = false;
            } else {
                this.error = true;
                this.loading = false;
            }

        } else if (this.tab === 1) {
            let iris = this.IRIsLines;

            this.loading = true;
            this.multipleLoading = true;
            this.multipleTotal = iris.length;
            this.multipleActual = 0;
            this.error = false;

            this.manipulator.blockAddFindMultipleNodes(iris, (node, success) => {
                this.multipleActual++;
                if (success) {
                    this.$root.$emit('addNode', node);
                    iris = iris.filter((iri) => iri != node);
                    this.IRIs = iris.join('\n');
                } else {
                    this.error = true;
                }
                if (this.multipleActual === this.multipleTotal) {
                    this.loading = false;
                    this.multipleLoading = false;
                    if (!this.error) {
                        this.dialog = false;
                    }
                }
            });
        }


    }

}

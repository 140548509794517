







import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import PropertyFilterData from "./PropertyFilterData";
import {Graph} from "../../../graph/Graph";
import EnumTab from "../../../component/filter/EnumTab.vue";

@Component({
    components: {
        EnumTab,
    }
})
export default class DegreeFilterSettingsTab extends Vue {
    @Prop() private filter !: PropertyFilterData;

    /**
     * Reference to graph instance because we need to ask some data...
     */
    @Prop() graph: Graph;

    private EqualityComparer = (a: string, b: string) => a == b;

    private get classes() {
        return Array.from(this.graph.getAllClasses());
    }
}


















































































































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import { Node } from '@/graph/Node';
import { NodeViewSet } from '@/graph/NodeViewSet';
import {DetailValue} from '@/graph/NodeView';
import 'vuetify/src/components/VBtnToggle/VBtnToggle.sass';
import {
    mdiTrashCanOutline,
    mdiRefresh,
    mdiCrosshairsGps,
    mdiEye,
    mdiEyeOff,
    mdiWeb,
    mdiPinOutline,
    mdiPinOffOutline,
    mdiApplicationExport,
    mdiMagnify,
    mdiGroup
} from '@mdi/js';
import GraphAreaManipulator from "../../graph/GraphAreaManipulator";
import LinkComponent from "../helper/LinkComponent.vue";
import NodeCommonPanelMixin from "./NodeCommonPanelMixin";
import PanelTemplate from "./components/PanelTemplate.vue";
import PanelActionButton from "./components/PanelActionButton.vue";
import GraphManipulator from "../../graph/GraphManipulator";
import AnyDataValue from "@/component/helper/AnyDataValue.vue";
import NodeCommon from '@/graph/NodeCommon';
import NodeGroup from '@/graph/NodeGroup';
import FacetedFiltering from "@/component/faceted-filtering/FacetedFiltering.vue";

@Component({
    components: {AnyDataValue, PanelActionButton, PanelTemplate, LinkComponent, FacetedFiltering}
})
export default class DetailPanel extends Mixins(NodeCommonPanelMixin) {
    @Prop(Object) node !: Node;
    @Prop(Object) areaManipulator !: GraphAreaManipulator;
    @Prop(Object) manipulator !: GraphManipulator;
    @Prop(Boolean) nodeLockingSupported !: boolean;

    private readonly icons = {
        remove: mdiTrashCanOutline,
        refresh: mdiRefresh,
        locate: mdiCrosshairsGps,
        visibility: [mdiEyeOff, mdiEye],
        link: mdiWeb,
        lockedForLayouts: [mdiPinOffOutline, mdiPinOutline],
        leave: mdiApplicationExport,
        zoomIcon: mdiMagnify,
        group: mdiGroup,
    }

    currentViewIRI: string = null;

    /**
     * Returns classes list with deterministic color based on name.
     *
     * Uses lastFullPreview to prevent unnecessary blinking when switching the views
     */
    get previewClasses(): {label: string; color: string}[] {
        return this.getClassesColors(this.node.lastPreview?.classes);
    }

    /**
     * Returns hierarchical class \
     * See the github documentation for more information: \
     *      - https://github.com/Razyapoo/KGBClusteringDocumentation/blob/main/technical_documentation.md#hierarchical-class
     */
    get getHierarchicalClass(): {label: string; color: string} {
        for (let cls of this.previewClasses) {
            if (cls.label === this.node.hierarchicalClass) {
                return cls;
            }
        }
    }

    get getVisualGroupClass(): {label: string; color: string} {
        for (let cls of this.previewClasses) {
            if (cls.label === this.node.visualGroupClass) {
                return cls;
            }
        }
    }

    /**
     * Converts viewSets to array
     */
    get viewSets(): NodeViewSet[]|null {
        if (this.node.viewSets === null) return null;
        let sets: NodeViewSet[] = [];
        for (let IRI in this.node.viewSets) {
            sets.push(this.node.viewSets[IRI]);
        }
        return sets;
    }

    /**
     * Extracts possible image URLs to show them as real images
     */
    get detailImages(): DetailValue[] {
        if (!this.node.lastDetail) {
            return null;
        }

        let result = this.node.lastDetail.filter(detail => ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.bmp', '.BMP'].includes(detail.value.substr(detail.value.length - 4)));

        return result.length ? result : null;
    }

    mounted() {
        this.nodeChanged();
    }

    async expand(view) {
        let expansion = await this.areaManipulator.expandNode(view);

        this.$root.$emit('expansion', [this.node, expansion.getNodes()]);
    }

    private searchValue: String = "";
    private filterNodes(nodes: NodeCommon[]) {
        // nodes = nodes.filter(node => node instanceof Node);

        nodes = nodes.slice().sort((n1,n2) => {
            let label1: string = "";
            let label2: string = "";


            if (n1 instanceof Node) label1 = n1.currentView?.preview?.label.toLowerCase();
            else if (n1 instanceof NodeGroup) label1 = n1.getName.toLowerCase();

            if (n2 instanceof Node) label2 = n2.currentView?.preview?.label.toLowerCase();
            else if (n2 instanceof NodeGroup) label2 = n2.getName.toLowerCase();

            if ( label1 > label2 ) {
                return 1;
            }

            if ( label1 < label2 ) {
                return -1;
            }

            return 0;
        });

        let filteredNodes = nodes;
        if (this.searchValue != "" && this.searchValue) {
            filteredNodes = nodes.filter(node => { 
                if (node instanceof Node) return node.currentView?.preview?.label.toLowerCase().includes(this.searchValue.toLowerCase());
                // else if (node instanceof NodeGroup) return node.mostFrequentType?.label.toLowerCase().includes(this.searchValue.toLowerCase());
                else if (node instanceof NodeGroup) return node.getName.toLowerCase().includes(this.searchValue.toLowerCase());
            })
        }

        return filteredNodes;
    }
    
    private isNode(node: NodeCommon) {
        return (node instanceof Node)
    }

    private getLabel(node: NodeCommon) {
        if (node instanceof Node) return node.currentView?.preview ? node.currentView.preview.label : "-";
        if (node instanceof NodeGroup) return node.getName;
    }

    public get isGroupCompactModeActive() {
        return this.areaManipulator.graphArea.modeGroupCompact;
    }

    public get isCompactModeActive() {
        return this.areaManipulator.graphArea.modeCompact;
    }

    visibilityChanged() {
        this.node.visible = !this.node.visible;
        if ((this.areaManipulator.visualGroups.length > 0) && this.node.parent?.identifier.startsWith("pseudo_parent")) {
            if (this.node.parent?.children?.every(childNode => childNode.visible === false)) this.node.parent.visible = false;
            else this.node.parent.visible = true;
        }
    }

    removeNode() {
        this.node.remove();

        this.$root.$emit('deletion', this.node);
    }

    /**
     * This functionality should remove all fetchable node information and download them again
     */
    nodeRefresh() {
        this.node.viewSets = null;
        this.node.viewSetsConfiguration = null;
        this.node.currentView = null;
        this.nodeChanged();
    }

    @Watch('node')
    async nodeChanged() {
        if (!this.node.viewSets) await this.node.fetchViewSets();
        if (!this.node.currentView?.IRI) await this.node.useDefaultView(); // Current view could have been obtained from expansion (in this case it won't contain IRI) and therefore it also needs to be replaced
        if (!this.node.currentView?.preview) await this.node.currentView?.fetchPreview();
        this.currentViewChanged();
    }

    @Watch('node.currentView')
    async currentViewChanged() {
        this.currentViewIRI = this.node.currentView?.IRI;
        if (this.node.currentView?.IRI) await this.node.currentView?.getDetail();
    }
}
